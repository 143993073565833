import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { UnityComponent } from '@shared/components';

import {
  useFetchGameBuild,
  useFetchGameDefaultsLazy,
} from '../Client/client.actions';

import { useIncreaseGameBuildRunCount } from './game.actions';

import './index.scss';

let isLocalhost = window.location.hostname === 'localhost';

const GamePage = () => {
  const [searchParams] = useSearchParams();
  const [response, increaseGameBuildRunCount, loading] = useIncreaseGameBuildRunCount();
  const [gameUrlPack, setGameUrlPack] = useState<string | null>(null);

  const currentBuildId = searchParams.get('buildId');

  useEffect(() => {
    if (currentBuildId !== null) {
      increaseGameBuildRunCount(currentBuildId);
    }
  }, [currentBuildId, increaseGameBuildRunCount]);

  useEffect(() => {
    if (!response) {
      console.log('no response');
      return;
    }

    const pack = (response as any).gameUrlPack;
    setGameUrlPack(pack);
    
    console.log('response ', response);
    console.log('gamePack', pack);
  }, [response]);

  const unityResolution = 1920 / 1080;

  // Ждём загрузки gameUrlPack перед выполнением остального кода
  // if (!response && loading) {
   // Ждём загрузки gameUrlPack и завершения запроса перед выполнением остального кода
   if (loading || gameUrlPack === null) {
    return <div>Loading...</div>;  // Можно отобразить индикатор загрузки
  }

  
  

  interface UrlConfig {
    loaderUrl: string;
    dataUrl: string;
    frameworkUrl: string;
    codeUrl: string;
  }
  
  let urlConfig: UrlConfig | Record<string, UrlConfig>;
  
  if (isLocalhost) {
    urlConfig = {
      loaderUrl: import.meta.env.VITE_LOADER as string,
      dataUrl: import.meta.env.VITE_DATA as string,
      frameworkUrl: import.meta.env.VITE_FRAMEWORK as string,
      codeUrl: import.meta.env.VITE_CODE as string,
    };
  } else {
    urlConfig = new Proxy<Record<string, UrlConfig>>({}, {
      get(target, key: string) {
        key = String(key); // Ensure key is a string
        if (key === 'default') key = '001'; // Default fallback
  
        return {
          loaderUrl: `${import.meta.env.VITE_URL}${key}/loader.js`,
          dataUrl: `${import.meta.env.VITE_URL}${key}/game.data`,
          frameworkUrl: `${import.meta.env.VITE_URL}${key}/framework.js`,
          codeUrl: `${import.meta.env.VITE_URL}${key}/code.wasm`,
        };
      },
    });
  }
  

// let urlConfig;

// if (isLocalhost) {
//   urlConfig = {
//     loaderUrl: import.meta.env.VITE_LOADER,
//     dataUrl: import.meta.env.VITE_DATA,
//     frameworkUrl: import.meta.env.VITE_FRAMEWORK,
//     codeUrl: import.meta.env.VITE_CODE,
//   };
// } else {
//   urlConfig = {
//     '001': {
//       loaderUrl: import.meta.env.VITE_URL + '001/loader.js',
//       dataUrl: import.meta.env.VITE_URL + '001/game.data',
//       frameworkUrl: import.meta.env.VITE_URL + '001/framework.js',
//       codeUrl: import.meta.env.VITE_URL + '001/code.wasm',
//     },
//     '002': {
//       loaderUrl: import.meta.env.VITE_URL + '002/loader.js',
//       dataUrl: import.meta.env.VITE_URL + '002/game.data',
//       frameworkUrl: import.meta.env.VITE_URL + '002/framework.js',
//       codeUrl: import.meta.env.VITE_URL + '002/code.wasm',
//     },
//     default: {
//       loaderUrl: import.meta.env.VITE_URL + '001/loader.js',
//       dataUrl: import.meta.env.VITE_URL + '001/game.data',
//       frameworkUrl: import.meta.env.VITE_URL + '001/framework.js',
//       codeUrl: import.meta.env.VITE_URL + '001/code.wasm',
//     }
//   };
// }

const gameKey = gameUrlPack || '001'; // Если gameUrlPack null, подставляем '001'
  const selectedConfig =
    isLocalhost ? (urlConfig as UrlConfig) : (urlConfig as Record<string, UrlConfig>)[gameKey];

  const { loaderUrl, dataUrl, frameworkUrl, codeUrl } = selectedConfig;

console.log('Loader URL:', loaderUrl);

  return (
    <Box className="mainPage">
      <UnityComponent
        loaderUrl={loaderUrl}
        dataUrl={dataUrl}
        frameworkUrl={frameworkUrl}
        codeUrl={codeUrl}
        resolution={unityResolution}
      />
    </Box>
  );
};

export default GamePage;
