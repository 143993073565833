import { useEffect, useCallback, useState } from 'react';

import type { GameKey } from '@shared/types';

import useAxios from '@services/api';

import type {
  MasterAvailableGames,
  CreateKeyFormInputs,
} from './create-key-form.types';




export const useFetchGames = () => {
  const { response, error, issueRequest } = useAxios<MasterAvailableGames[]>();

  useEffect(() => {
    void issueRequest({ url: `/admin/games`, method: 'get' });
  }, []);

  return [response];
};



export const useCreateKey = () => {
  const { response, error, issueRequest, loading } = useAxios<GameKey>();

  // Загружаем список игр
  const [games] = useFetchGames();
  console.log('Loaded games: ', games); // Лог здесь

  const handleSubmit = useCallback(
    (data: CreateKeyFormInputs) => {
      const { gamesCount, editDayCount, gameDayCount, gameName } = data;

      // Находим игру по gameName
      const selectedGame = games?.find((game) => game.title === gameName);

      if (!selectedGame) {
        console.error('Игра не найдена');
        return;
      }

      // Извлекаем gameUrlPack из выбранной игры
      const { gameUrlPack } = selectedGame;

      console.log('Loaded selectedGame: ', selectedGame); // Лог здесь
      console.log('Loaded gameUrlPack: ', gameUrlPack); // Лог здесь

      // Отправляем запрос с добавленным gameUrlPack
      void issueRequest({
        url: '/admin/games/keys',
        method: 'post',
        body: {
          ...data,
          gamesCount: Number(gamesCount),
          editDayCount: Number(editDayCount),
          gameDayCount: Number(gameDayCount),
          gameUrlPack, // Добавляем gameUrlPack в тело запроса
        },
      });
    },
    [games, issueRequest]
  );

  return [response, handleSubmit, loading] as const;
};

// export const useCreateKey = () => {
//   const { response, error, issueRequest, loading } = useAxios<GameKey>();

//   // Загружаем список игр
//   const [games] = useFetchGames();

//   const handleSubmit = useCallback((data: CreateKeyFormInputs) => {
//     const { gamesCount, editDayCount, gameDayCount } = data;
//     console.log("Список игр:", games);

//     void issueRequest({
//       url: `/admin/games/keys`,
//       method: 'post',
//       body: {
//         ...data,
//         gamesCount: Number(gamesCount),
//         editDayCount: Number(editDayCount),
//         gameDayCount: Number(gameDayCount),
//       },
//     });
//   }, []);

//   return [response, handleSubmit, loading] as const;
// };
