import React, { FC, useState, useEffect, ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography, Stack } from '@mui/material';

import Tooltip from '@shared/components/Tooltip';
import type { FileEventTarget } from '@shared/types';

import { readFile } from '../file-utils';

import CropperOverlay from './CropperOverlay';
import type { MobileCropperProps } from './mobile-cropper.types';

import styles from '../image-cropper.module.scss';

const MAX_SIDE = 50;

// Вспомогательная функция для уменьшения изображения
const resizeImage = (imageSrc: string, maxWidth: number): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const scaleFactor = maxWidth / img.width;
      const canvas = document.createElement('canvas');
      canvas.width = maxWidth;
      canvas.height = img.height * scaleFactor;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL('image/jpeg', 0.8)); // Сохранение с качеством 80%
    };
  });
};

const MobileCropper: FC<MobileCropperProps> = ({
  onChange,
  value,
  maskImage,
  aspectRatio,
  isDisabled = false,
  label,
  toolTipContent,
  containerClassName = '',
  fieldName,
  onCroppedPixelsChange,
}) => {
  const { t } = useTranslation(); // <-- Хук для получения переводов

  const [errorImage, setErrorImage] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (!value) {
      setImageSrc('');
      return;
    }

    if (typeof value === 'string') {
      setImageSrc(value);
    } else {
      setFile(value);
      readFile(value).then((res) => {
        setImageSrc(res);
        onChange(value);
      });
    }
  }, [value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as FileEventTarget;

    if (target.files && target.files.length > 0) {
      const temp_file = target.files[0];
      readFile(temp_file)
        .then((res) => {
          setImageSrc(res);
          setFile(temp_file);
          onChange(temp_file);
        })
        .catch((e) => {
          setErrorImage(true);
          setImageSrc(imageSrc);
        });
    } else {
      setErrorImage(true);
    }
  };

  const handleConfirmClick = (croppedImage: File | string | null) => {
    if (!croppedImage) {
      return;
    }

    if (typeof croppedImage === 'string') {
      setImageSrc(croppedImage);
      onChange(croppedImage);
    } else {
      setFile(croppedImage);
      readFile(croppedImage).then((file) => {
        setImageSrc(file);
        // setImageSrc(croppedImage);
        onChange(croppedImage);
      }).catch(console.error);
    }
  };

  const getFileName = () => {
    if (file) {
      return file.name;
    }

    if (imageSrc) {
      return imageSrc.split('/').pop() || imageSrc;
    }


    return t('cropper.uploadImage');
    // return 'Upload Image';
  };

  const handleClearClink = () => {
    setImageSrc('');
    setFile(undefined);

    onCroppedPixelsChange(fieldName, null);

    onChange(null);
  };

  return (
    <div className={`${styles.imageCropperWrapper} ${containerClassName}`}>
      {errorImage && (
        <Box className={styles.errorModalWrapper}>
          <Box component="div" className={styles.errorModal}>
            <Typography variant="h6" noWrap className="cropperLabel">
              Image should be less than 5.000 px
            </Typography>
            <Box className={styles.errorModalBtnWrapper}>
              <Button
                color="error"
                size="small"
                className={styles.errorModalBtn}
                onClick={() => setErrorImage(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Stack className={styles.imageCropperHeader} spacing={1}>
        <Stack
          className={`${styles.imageCropperHeader} tooltipWrapperInline`}
          direction="row"
        >
          <Typography variant="h6" noWrap className="cropperLabel">
            {label}
          </Typography>
          {!!toolTipContent && (
            <Tooltip className="tooltip" content={toolTipContent} />
          )}
        </Stack>

        {imageSrc && (
          <Box component="div" className={styles.cropArea}>
            <Box
              className={styles.imagePreview}
              component="img"
              src={imageSrc}
            />
          </Box>
        )}

        <Stack spacing={1} className={styles.actionButtons}>
          <Button
            variant="contained"
            size="small"
            disabled={isDisabled}
            component="label"
            className="uploadBtn"
          >
            <span className="uploadBtnText">{getFileName()}</span>
            <input
              accept="image/*"
              type={'file'}
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {imageSrc && (
            <Button
              size="small"
              disabled={isDisabled}
              variant="outlined"
              color="success"
              onClick={handleOpen}
            >
              {t('client.zoomCrop')}
            </Button>
          )}
          {imageSrc && (
            <Button
              size="small"
              disabled={isDisabled}
              color="error"
              onClick={handleClearClink}
            >
               {t('client.clear')}
            </Button>
          )}
        </Stack>
      </Stack>

      <CropperOverlay
        key={fieldName + open}
        onConfirm={handleConfirmClick}
        onClose={handleClose}
        isOpen={open}
        imageSrc={imageSrc}
        file={file}
        maskImage={maskImage}
        aspectRatio={aspectRatio}
        fileName={getFileName()}
        fieldName={fieldName}
        onCroppedPixelsChange={(fName, croppedPixels) => {
          console.log(`✅ onCroppedPixelsChange: ${fName}`, croppedPixels);
          onCroppedPixelsChange(fName, croppedPixels);
        }}
      />
    </div>
  );
};

export default memo(MobileCropper);
