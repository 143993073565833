import React, { FC, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import ClientPage from '@pages/Client';
import GamePage from '@pages/Game';
import LoginPage from '@pages/Login';
import MasterPage from '@pages/Master';

import { Layout } from '@shared/components';

import { authContext } from '@store/index';

import ConditionalRoute from './ConditionalRoute';

const Router: FC = () => {
  const [authData] = useContext(authContext);

  const getDashboardComponent = () => {
    if (!authData) {
      return null;
    }

    if (authData.masterKey) {
      return <MasterPage masterKey={authData.masterKey} />;
    }

    if (authData.gameKey) {
      return <ClientPage gameKey={authData.gameKey} />;
    }
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <ConditionalRoute condition={!authData} redirectTo="/">
            <LoginPage />
          </ConditionalRoute>
        }
      />
      <Route path="/game" element={<GamePage />} />
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ConditionalRoute condition={!!authData} redirectTo="/login">
              {getDashboardComponent()}
            </ConditionalRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default Router;
