// @ts-nocheck

import { useEffect, useCallback } from 'react';

import { pick, omit } from 'lodash';

import type { Game } from '@shared/types';

import useAxios from '@services/api';

import { GameBuild } from './client.types';
import type { SubmittedForm, CropPixelsData } from './client.types';
import { cropImage, rotateImages, resizeImage } from './client.utils';

const fixedPixels = { x: 100, y: 100, width: 200, height: 200 };

export const useFetchGameDefaults = (name: string) => {
  const { response, error, issueRequest } = useAxios<Game>();

  useEffect(() => {
    void issueRequest({ url: `/games/${name}`, method: 'get' });
  }, [name]);

  return response;
};

export const useFetchGameDefaultsLazy = () => {
  const { response, error, issueRequest, loading } = useAxios<Game>();

  const useFetchGameDefaultsLazy = useCallback((name: string) => {
    void issueRequest({ url: `/games/${name}`, method: 'get' });
  }, []);

  return [response, useFetchGameDefaultsLazy, loading] as const;
};

export const useFetchGameBuild = () => {

  const { response, error, issueRequest } = useAxios();

  const useFetchGameBuild = useCallback((gameId: string) => {
    void issueRequest({ url: `/game/build/${gameId}`, method: 'get' });
    // void issueRequest({ url: `/game/build/${id}`, method: 'get', headers: { 'x-api-key': '38f8ef5e-8c83-416d-9b6d-bd9980c44429'} });
  }, []);
  return [response, useFetchGameBuild, error] as const;
};

// export const useFetchGameBuild = (id: string) => {
//   const { response, error, issueRequest } = useAxios<GameBuild>();

//   useEffect(() => {
//     if (!id) return;  // Проверка на пустой ID

//     void issueRequest({ 
//       url: `/game/build/${id}`, 
//       method: 'get', 
//       headers: {
//         'x-api-key': '38f8ef5e-8c83-416d-9b6d-bd9980c44429',
//       },
//     });
//   }, [id]);

//   return [response, error] as const;
// };


export const useCreateGameBuild = () => {
  const { response, error, issueRequest, loading, setLoading } =
    useAxios<GameBuild>();

  const handleSubmit = useCallback(
    (
      data: SubmittedForm,
      croppedPixelsData: CropPixelsData,
      tiles: string[]
    ) => {
      setLoading(true);

      const pendingPromises = [];

      for (const [fieldName, pixels] of Object.entries(croppedPixelsData)) {
        if (!pixels) {
          continue;
        }
        if (!isNaN(parseInt(fieldName, 10))) {
          pendingPromises.push(
            cropImage(data.gameTiles[fieldName], pixels).then(
              (croppedImage) => {
                data.gameTiles[fieldName] = croppedImage;
              }
            )
          );
        } else {
          pendingPromises.push(
            cropImage(data[fieldName], pixels).then((croppedImage) => {
              data[fieldName] = croppedImage;
            })
          );
        }
      }

      Promise.all(pendingPromises)
        // .then(() => {



          // // **Добавляем уменьшение разрешения после обрезки**
          // const resizePromises = [];

          // // Поля, которые нужно уменьшить
          // const resizeFields = ['startGameImage', 'endGameImage'];

          // // Уменьшаем startGameImage и endGameImage
          // for (const fieldName of resizeFields) {
          //   if (data[fieldName] instanceof File) {
          //     resizePromises.push(
          //       resizeImage(data[fieldName] as File, 200).then((resizedImage) => {
          //         data[fieldName] = resizedImage;
          //       })
          //     );
          //   }
          // }

          // // Уменьшаем каждое изображение в gameTiles
          // for (let i = 0; i < data.gameTiles.length; i++) {
          //   if (data.gameTiles[i] instanceof File) {
          //     resizePromises.push(
          //       resizeImage(data.gameTiles[i] as File, 200).then((resizedTile) => {
          //         data.gameTiles[i] = resizedTile;
          //       })
          //     );
          //   }
          // }

          // // Ждем завершения всех операций уменьшения
          // return Promise.all(resizePromises);
        // })
        .then(() => {

          
          data.gameTiles = rotateImages(tiles);

          const formData = new FormData();

          const fileFields = [
            'startGameImage',
            'endGameImage',
            'gameTiles',
            'customMusicFile',
          ];
          const fileUrls = <(string | null)[]>[];

          if (!data.enableCustomMusic) {
            delete data.customMusicFile;
          }

          // Why lodash doesn't infer types
          const files: Pick<
            SubmittedForm,
            'startGameImage' | 'endGameImage' | 'gameTiles' | 'customMusicFile'
          > = pick(data, fileFields);
          const plainFields: Omit<
            SubmittedForm,
            'startGameImage' | 'endGameImage' | 'customMusicFile' | 'gameTiles'
          > & { gameTiles: (string | null)[] } = omit(data, fileFields);

          // How to specify types for object.entries
          for (const [fieldName, fileObject] of Object.entries(files)) {
            // Why type guard didn't work
            if (fieldName === 'gameTiles') {
              for (const imageFile of files[fieldName]) {
                if (typeof imageFile === 'string') {
                  fileUrls.push(imageFile);
                } else {
                  fileUrls.push(null);
                  formData.append(fieldName, imageFile);
                }
              }
            } else {
              formData.append(fieldName, fileObject);
            }
          }

          plainFields.gameTiles = fileUrls;

          const jsonData = JSON.stringify(plainFields);

          formData.append('jsonData', jsonData);

          void issueRequest({
            url: `/games/build`,
            method: 'put',
            body: formData,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  return [response, handleSubmit, loading] as const;
};

export const useSetActivateGameDate = () => {
  const { response, error, issueRequest } = useAxios<Record<string, never>>();

  const useSetActivateGameDate = useCallback((id: string) => {
    void issueRequest({ url: `/games/keys/${id}`, method: 'put' });
  }, []);

  return [response, useSetActivateGameDate] as const;
};
