// @ts-nocheck

import React, { FC, useEffect, useState, useRef } from 'react';
import { Area } from 'react-easy-crop';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next'; // <-- Импорт i18next
import { ClipLoader } from 'react-spinners';

import i18n from 'i18next'; // Импорт i18next для смены языка
import { omit, isEmpty } from 'lodash';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language'; // Иконка для кнопки выбора языка
import {
  Container,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Alert,
  AlertTitle,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';

import Tooltip from '@shared/components/Tooltip';
import type { GameKey } from '@shared/types';

import * as ClientActions from './client.actions';
import { TOOLTIPS_CONTENT, REQUIRED_TILES_AMOUNT } from './client.constants';
import { FormInputs, CropPixelsData } from './client.types';
import {
  generateGameLink,
  getMusicUrl,
  getEndEditDate,
  getIsClientFormDisabled,
} from './client.utils';
import ClipboardBtn from './ClipboardBtn';
import ExampleImagesCarousel from './ExampleImagesCarousel';
import { MobileCropper, DesktopCropper } from './ImageCropper';
import type { ImageCropperProps } from './ImageCropper/image-cropper.types';
import MusicUploader from './MusicUploader';

import styles from './client.module.scss';

interface ClientPageProps {
  gameKey: GameKey;
}

const ClientPage: FC<ClientPageProps> = ({ gameKey }) => {
  const { t } = useTranslation(); // <-- Хук для получения переводов

  const { gameBuild: alreadyExistedGameBuild } = gameKey;
  const [_, setActivateGameDate] = ClientActions.useSetActivateGameDate();
  const gameDefaults = ClientActions.useFetchGameDefaults(gameKey.gameName);
  // console.log("🚀 ~ gameDefaults:", gameDefaults)
  const [existingGameBuild, fetchGameBuild, gameBuildError] = ClientActions.useFetchGameBuild(
    gameKey.gameBuildId
  );

  const [createdGameBuild, handleGameBuildSubmit, gameBuildCreationLoading] =
    ClientActions.useCreateGameBuild();
  const [showGameLink, setShowGameLink] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [windowRef, setWindowRef] = useState<Window>();
  const croppedPixelsDataRef = useRef<{ [key: string]: Area }>({});
  // const [croppedPixelsData, setCroppedPixelsData] = useState<CropPixelsData>(
  //   {}
  // );
  const [files, setFiles] = useState<File[]>([...Array(14)]);

  const gameBuild = createdGameBuild ?? alreadyExistedGameBuild;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Открытие меню выбора языка
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Закрытие меню выбора языка
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Смена языка
  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng); // Сменить язык
    setAnchorEl(null); // Закрыть меню
  };

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,

    getValues,
    setValue, // Убедитесь, что эта строка есть
    trigger, // Убедитесь, что эта строка есть
  } = useForm<FormInputs>();

  useEffect(() => {
    if (gameBuild && isTesting) {
      setFiles([
        gameBuild.startGameImageUrl,
        gameBuild.endGameImageUrl,
        ...gameBuild.gameTilesUrls,
      ]);

      if (windowRef) {
        windowRef.location = generateGameLink(gameBuild._id, true);
      }

      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 50);
    } else if (gameBuild) {
      setFiles([
        gameBuild.startGameImageUrl,
        gameBuild.endGameImageUrl,
        ...gameBuild.gameTilesUrls,
      ]);
      setShowGameLink(true);
    }
  }, [gameBuild, isTesting, windowRef]);

  useEffect(() => {
    reset({
      language: gameBuild?.language || existingGameBuild?.language || 'English',
      startGameHeader:
        gameBuild?.startGameHeader || existingGameBuild?.startGameHeader || '',
      endGameHeader:
        gameBuild?.endGameHeader || existingGameBuild?.endGameHeader || '',

      urlButton: gameBuild?.urlButton || existingGameBuild?.urlButton || '',

      startGameImage:
        gameBuild?.startGameImageUrl ||
        existingGameBuild?.startGameImageUrl ||
        '',
      endGameImage:
        gameBuild?.endGameImageUrl || existingGameBuild?.endGameImageUrl || '',
      withTime:
        gameBuild?.timeAttemptsEnabled ||
        existingGameBuild?.timeAttemptsEnabled ||
        true,
      enableCustomMusic: !!getMusicUrl(
        gameBuild,
        existingGameBuild,
        gameDefaults
      ),
      customMusicFile: getMusicUrl(gameBuild, existingGameBuild, gameDefaults),
      gameTiles:
        gameBuild?.gameTilesUrls || existingGameBuild?.gameTilesUrls || [],
    });
  }, [existingGameBuild, gameDefaults, gameBuild, reset]);

  const isCustomMusicEnabled = useWatch({
    control,
    name: 'enableCustomMusic',
    defaultValue: false,
  });

  const language = useWatch({
    control,
    name: 'language',
  });
  const [spinner, setSpinner] = useState(false);

  const onSubmit = async (data: FormInputs) => {
    const tiles: Array<string | File> = data.gameTiles;

    if (tiles.filter((v) => !!v).length < REQUIRED_TILES_AMOUNT) {
      setError('root.gameTiles', {
        message: `${REQUIRED_TILES_AMOUNT} фотографий должно быть.`,
      });

      return;
    }

    setSpinner(true);

    await handleGameBuildSubmit(
      { _id: gameKey.gameBuildId, ...data },
      croppedPixelsDataRef.current,
      tiles
    );

    if (!gameKey.activateDate) {
      await setActivateGameDate(gameKey._id);
    }
  };

  const handleTestGameClick = () => {
    setIsTesting(true);

    handleSubmit((data) => {
      setWindowRef(window.open(undefined, '_blank'));
      onSubmit(data);
    })();
  };

  const handleUploadMultipleImages = async (event) => {
    const files = event.target.files;

    if (files.length > 14) {
      alert('Need no more than 14 images');
      return;
    }

    const filesArray = Array.from(files);

    while (filesArray.length < 14) {
      filesArray.push(null);
    }

    setFiles(filesArray);
    // Для startGameImage и endGameImage, проверяем и обновляем значения, если изображение есть
    if (filesArray[0] && filesArray[0] !== getValues('startGameImage')) {
      setValue('startGameImage', filesArray[0]);
    }
    if (filesArray[1] && filesArray[1] !== getValues('endGameImage')) {
      setValue('endGameImage', filesArray[1]);
    }

    // Для gameTiles (с 2 до 13), проверяем и обновляем значения, если изображение есть
    filesArray.slice(2).forEach((file, index) => {
      const fieldName = `gameTiles.${index}`;
      if (file && file !== getValues(fieldName)) {
        // Если новое изображение отличается от текущего
        setValue(fieldName, file); // Обновляем только если изображение изменилось
      }
    });
  };

  const handleCreateGameClick = () => {
    setIsTesting(false);

    handleSubmit(onSubmit)();
  };

  const handleCroppedPixelsChange = (
    fieldName: string,
    croppedPixels: Area
  ) => {
    // croppedPixelsDataRef.current[fieldName] = { ...croppedPixels };
    croppedPixelsDataRef.current[fieldName] = croppedPixels;
    
  };

  const renderCropper = (props: ImageCropperProps) => {
    // if (true) {
      return <MobileCropper {...omit(props, 'headerDirection')} />;
    // }

    // return <DesktopCropper {...props} />;
  };

  if (!gameDefaults || gameBuildError) {
    return null;
  }

  const { activateDate, editDayCount } = gameKey;

  const formattedEndEditDate =
    activateDate !== null ? getEndEditDate(activateDate, editDayCount) : '';

  const isClientFormDisabled = getIsClientFormDisabled(
    activateDate,
    editDayCount
  );

  return (
    <Container className={styles.gameContainer} maxWidth="xl">
      <div className={styles.languageContainer}>
        <span className={styles.languageText}>{t('global.language')}</span>
        <IconButton onClick={handleOpenMenu} className={styles.iconButton}>
        <LanguageIcon style={{ fontSize: 24, color: 'white' }} /> {/* Задаем цвет и размер иконки */}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => handleLanguageChange('en-EN')}>
            {t('English')}
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange('ru-RU')}>
            {t('Русский')}
          </MenuItem>
        </Menu>
      </div>

      <Typography variant="h4" className={styles.gameTitle} noWrap>
        {gameDefaults.title}
      </Typography>

      {formattedEndEditDate !== '' && (
        <Typography color="red" textAlign="center" margin="20px">
          {t('client.canEditTill')} {formattedEndEditDate}
        </Typography>
      )}

      <form onSubmit={(...args) => void handleSubmit(onSubmit)(...args)}>
        <Grid rowSpacing={3} container>
          {language && (
            <>
              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid item xs={12} md={10} lg={6}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{t('client.videoManual')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={styles.videoContainer}>
                        <iframe
                          className={styles.videoFrame}
                          src="https://www.youtube.com/embed/M1b3DZ_glZ4?si=X4jUHjWpJa7VjTN1"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid item xs={12} md={10} lg={6}>
                  <ExampleImagesCarousel
                    imageUrls={[
                      gameDefaults.startGameImageExampleUrl,
                      gameDefaults.endGameImageExampleUrl,
                      gameDefaults.gameTileExampleUrl,
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid item xs={9} sm={6} lg={3}>
                  <Stack
                    className={`${styles.justifyCenter} tooltipWrapper`}
                    direction="row"
                  >
                    <Controller
                      name="startGameHeader"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={isClientFormDisabled}
                          error={!!errors?.startGameHeader}
                          fullWidth
                          label={t('client.text1page')}
                        />
                      )}
                    />
                    <Tooltip
                      className="tooltip"
                      content={t('client.text1pageTooltip')}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid item xs={9} sm={6} lg={3}>
                  <Stack
                    className={`${styles.justifyCenter} tooltipWrapper`}
                    direction="row"
                  >
                    <Controller
                      name="endGameHeader"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          disabled={isClientFormDisabled}
                          error={!!errors?.endGameHeader}
                          label={t('client.textFinalPage')}
                        />
                      )}
                    />
                    <Tooltip
                      className="tooltip"
                      content={t('client.textFinalPageTooltip')}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid item xs={9} sm={6} lg={3}>
                  <Stack
                    className={`${styles.justifyCenter} tooltipWrapper`}
                    direction="row"
                  >
                    <Controller
                      name="urlButton"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          disabled={isClientFormDisabled}
                          error={!!errors?.urlButton}
                          label={t('client.linkUrl')}
                        />
                      )}
                    />
                    <Tooltip
                      className="tooltip"
                      content={t('client.linkTooltip')}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item xs={12} className={styles.justifyCenter}>
                <Grid
                  item
                  xs={12}
                  className={`${styles.justifyCenter} flex`}
                  style={{ marginTop: '20px' }}
                >
                  <Stack
                    className={`${styles.justifyCenter} tooltipWrapperInline`}
                    direction="row"
                  >
                    <Typography variant="h6" noWrap>
                      {t('client.selectMultipleImages')}
                    </Typography>
                    <Tooltip
                      className="tooltip"
                      content={t('client.selectMultipleImagesTooltip')}
                    />
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ marginBottom: '40px', marginTop: '10px' }}
                >
                  <Grid container justifyContent="center">
                    <Button
                      className={styles.uploadMultipleButton}
                      variant="contained"
                      component="label"
                    >
                      {t('client.uploadMultiple')}
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        hidden
                        onChange={handleUploadMultipleImages}
                      />
                    </Button>
                  </Grid>
                </Grid>

                {/* Существующий Grid для выбора изображения */}
                <Grid
                  className={styles.justifyCenter}
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                >
                  <Controller
                    name="startGameImage"
                    control={control}
                    style={{ height: 500, width: 500 }}
                    rules={{ required: t('client.selectMainImageReq') }}
                    render={({ field }) => {
                      return renderCropper({
                        maskImage: gameDefaults.maskImageUrl,
                        aspectRatio: 1 / 1,
                        isDisabled: isClientFormDisabled,
                        value: files[0] ? files[0] : field.value,
                        onChange: field.onChange,
                        toolTipContent: t('client.selectMainImageTooltip'),
                        label: t('client.selectMainImage'),
                        onCroppedPixelsChange: (fieldName, croppedPixels) => {
                          console.log('fieldName inside onCroppedPixelsChange: ', fieldName);
                          handleCroppedPixelsChange(fieldName, croppedPixels);
                        },
                        fieldName: 'startGameImage',
                      });
                    }}
                  />
                  {errors.startGameImage && (
                    <FormHelperText className={styles.errorText} error>
                      {errors.startGameImage.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Controller
                    name="endGameImage"
                    control={control}
                    rules={{ required: t('client.selectFinalImageReq') }}
                    render={({ field }) =>
                      renderCropper({
                        maskImage: gameDefaults.maskImageUrl,
                        aspectRatio: 1 / 1,
                        value: files[1] ? files[1] : field.value,
                        isDisabled: isClientFormDisabled,
                        onChange: field.onChange,
                        toolTipContent: t('client.selectFinalImageTooltip'),
                        label: t('client.selectFinalImage'),
                        onCroppedPixelsChange: (fieldName, croppedPixels) => {
                          console.log('fieldName inside onCroppedPixelsChange: ', fieldName);
                          handleCroppedPixelsChange('endGameImage', croppedPixels);
                        },
                        fieldName: 'endGameImage',
                      })
                    }
                  />
                  {errors.endGameImage && (
                    <FormHelperText className={styles.errorText} error>
                      {errors.endGameImage.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={`${styles.justifyCenter} flex`}
                style={{ marginTop: '40px' }}
              >
                <Stack
                  className={`${styles.justifyCenter} tooltipWrapperInline`}
                  direction="row"
                >
                  <Typography variant="h6" noWrap>
                    {t('client.selectImages')}
                  </Typography>
                  <Tooltip
                    className="tooltip"
                    content={t('client.selectImagesReq')}
                  />
                </Stack>
              </Grid>

              <Grid spacing={3} container item xs={12}>
                {files.slice(2).map((el, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <Controller
                      name={`gameTiles.${index}`}
                      control={control}
                      render={({ field }) => {
                        return renderCropper({
                          aspectRatio: 19 / 25,
                          value: el ? el : field.value,
                          onChange: field.onChange,
                          isDisabled: isClientFormDisabled,
                          label: index + 1,
                          headerDirection: 'row',
                          containerClassName: styles.tilesCropperWrapper,
                          onCroppedPixelsChange: handleCroppedPixelsChange,
                          fieldName: index,
                        });
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid
                  item
                  xs={10}
                  sm={6}
                  lg={3}
                  className={`${styles.timeAndAttemptsWrapper} tooltipWrapperInline`}
                >
                  <FormControlLabel
                    control={
                      <Controller
                        name="withTime"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            disabled={isClientFormDisabled}
                            checked={field.value}
                          />
                        )}
                      />
                    }
                    label={t('client.turnOnTimer')}
                  />
                  <Tooltip
                    className="tooltip"
                    content={t('client.turnOnTimerTooltip')}
                  />
                </Grid>
              </Grid>

              <Grid className={styles.justifyCenter} container item xs={12}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  className={`${styles.timeAndAttemptsWrapper}`}
                >
                  <Stack
                    className={`${styles.imageCropperHeader} ${styles.justifyCenter} tooltipWrapperInline`}
                    direction="row"
                  >
                    <FormControlLabel
                      control={
                        <Controller
                          name="enableCustomMusic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              disabled={isClientFormDisabled}
                              checked={field.value}
                            />
                          )}
                        />
                      }
                      label={t('client.turnOnMusic')}
                    />
                    <Tooltip
                      className="tooltip"
                      content={t('client.turnOnMusicTooltip')}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid className={styles.justifyCenter} container item xs={12}>
                {isCustomMusicEnabled && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={3}
                    className={styles.timeAndAttemptsWrapper}
                  >
                    <Controller
                      name="customMusicFile"
                      control={control}
                      render={({ field }) => (
                        <MusicUploader
                          value={field.value}
                          isDisabled={isClientFormDisabled}
                          onChange={field.onChange}
                          setError={setError}
                        />
                      )}
                    />
                    {errors.customMusicFile && (
                      <FormHelperText error className={styles.errorText}>
                        {errors.customMusicFile.message}
                      </FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid container item xs={12} className={styles.justifyCenter}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={styles.justifyCenter}
                >
                  {errors?.root?.gameTiles && (
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {errors.root.gameTiles.message}
                    </Alert>
                  )}
                </Grid>
              </Grid>

              {!isEmpty(errors) && (
                <Grid container item xs={12} className={styles.justifyCenter}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className={styles.justifyCenter}
                  >
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {t('client.alertEmptyField')}
                    </Alert>
                  </Grid>
                </Grid>
              )}
              {showGameLink && (
                <Grid item xs={12} className={`${styles.justifyCenter} flex`}>
                  <Stack className={`tooltipWrapperInline`} direction="row">
                    <Button
                      size="small"
                      disabled={gameBuildCreationLoading}
                      onClick={handleTestGameClick}
                      variant="contained"
                    >
                      {t('client.testGame')}
                    </Button>
                    <Tooltip
                      className="tooltip"
                      content={t('client.testGameTooltip')}
                    />
                  </Stack>
                </Grid>
              )}

              <Grid item xs={12} className={`${styles.justifyCenter} flex`}>
                <Stack className={`tooltipWrapperInline`} direction="row">
                  {spinner && !showGameLink && (
                    <ClipLoader
                      color="#1976d2"
                      size={19}
                      className={`${styles.spinner}`}
                    />
                  )}
                  <Button
                    size="small"
                    disabled={gameBuildCreationLoading}
                    onClick={handleCreateGameClick}
                    variant="contained"
                  >
                    {t('client.createGame')}
                  </Button>
                  <Tooltip
                    className="tooltip"
                    content={t('client.createGameTooltip')}
                  />
                </Stack>
              </Grid>

              {showGameLink && (
                <Grid item xs={12} className={`${styles.justifyCenter} flex`}>
                  <Stack className={`tooltipWrapper`} direction="row">
                    <ClipboardBtn
                      content={generateGameLink(gameBuild._id, false)}
                    />
                    <Tooltip
                      className="tooltip"
                      content={t('client.linkReadyGameTooltip')}
                    />
                  </Stack>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default ClientPage;
