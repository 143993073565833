
import { useCallback, useEffect } from 'react';

import useAxios from '@services/api';

// const SECRET_API_KEY = process.env.API_KEY || 'your-secret-api-key';

export const useIncreaseGameBuildRunCount = () => {
  const { response, error, issueRequest, loading } = useAxios();

  const increaseGameBuildRunCount = useCallback((buildId: string) => {
    void issueRequest({ url: `/game/build/${buildId}`, method: 'put' });
    // void issueRequest({ url: `/game/build/${buildId}`, method: 'put', headers: { 'x-api-key': '38f8ef5e-8c83-416d-9b6d-bd9980c44429'} });
  }, []);

  return [response, increaseGameBuildRunCount, loading] as const;
};
