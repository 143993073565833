import React, { FC, useState, useEffect, useCallback, memo, useRef } from 'react';
import Cropper, { Area } from 'react-easy-crop';

import { Backdrop, Box, Button, Stack } from '@mui/material';

import { getCroppedImg, createImage } from '../../file-utils';

import styles from './cropper-overlay.module.scss';

export interface CropperOverlayProps {
  onClose: () => void;
  onConfirm: (image: File | string | null) => void;
  file: File | undefined;
  isOpen: boolean;
  imageSrc: string;
  maskImage: string;
  aspectRatio: number;
  fileName: string;
  onCroppedPixelsChange: (fieldName: string, croppedArea: Area | null) => void;
  fieldName: string;
}

const CropperOverlay: FC<CropperOverlayProps> = ({
  onConfirm,
  onClose,
  file,
  isOpen,
  imageSrc,
  maskImage,
  aspectRatio,
  fileName,
  onCroppedPixelsChange,
  fieldName,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [canvasImage, setCanvasImage] = useState<HTMLImageElement>();
  const [croppedPixels, setCroppedPixels] = useState<Area>();

  // Счетчик количества обрезок
  const [cropCount, setCropCount] = useState(0);

  const fieldNameRef = useRef(fieldName);

useEffect(() => {
  fieldNameRef.current = fieldName;
}, [fieldName]);


  useEffect(() => {
    if (!isOpen) {
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedPixels(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    if (imageSrc) {
      createImage(imageSrc).then(setCanvasImage).catch(console.error);
    }
  }, [imageSrc]);

  const handleCropComplete = (croppedArea: Area, croppedPixels: Area) => {
    setCroppedPixels(croppedPixels);
    onCroppedPixelsChange(fieldNameRef.current, croppedPixels);
  };

  const handleSaveClick = () => {
    onCroppedPixelsChange(fieldName, null);

    if (!croppedPixels) {
      onConfirm(file || imageSrc);
      setZoom(1);
      setCrop({ x: 0, y: 0 });
      setCroppedPixels(undefined);
      onClose();

      return;
    }

    if (canvasImage) {
      /// Увеличиваем счетчик обрезок
    setCropCount((prevCount) => {
      const newCount = prevCount + 1;
      return newCount;
    });

      // Создаём новый canvas для обрезки
      getCroppedImg(canvasImage, croppedPixels, fileName)
        .then((croppedImage) => {
          console.log(croppedImage);
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          onConfirm(croppedImage);
          setTimeout(() => onClose(), 50);
        })
        .catch(console.error);
    }
  };

  return (
    <Backdrop className={styles.backDrop} open={isOpen}>
      <Stack className={styles.backdropStack} spacing={1}>
        <Box component="div" className={styles.cropArea}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            zoomSpeed={0.25}
            style={{
              cropAreaStyle: {
                ...(maskImage
                  ? {
                      backgroundImage: `url(${maskImage})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }
                  : {}),
              },
            }}
            objectFit="vertical-cover"
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
        </Box>
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={handleSaveClick}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
      </Stack>
    </Backdrop>
  );
};

export default CropperOverlay;
